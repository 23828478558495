import React, { useContext, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";

import EventLogItems from "../EventLogItems/EventLogItems";

import { useCanEdit } from "../../../Base/Hooks/canEdit";
import {
  convertClock,
  convertDisplayTimeToDataTime,
  convertEventTimeClock,
  convertToFullDisplayTime,
} from "../../../Base/Utilities/ConvertClock";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";

import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import defaultSetup from "../../../../Config/defaultSetupConfiguration";
import { getExtraPlayInfo } from "../../../Base/Utilities/EventLog";
import { getLastValidPlayFromPeriod, getPeriodEndEvent } from "../../../Base/Utilities/Period";
import { getEventTimeForUpdatedDeletedEvents, sendEvent } from "../../../Base/Utilities/SendEvent";
import EventLogDropdown from "../EventLogDropdown/EventLogDropdown";
import ClockEditor from "../ClockEditor/ClockEditor";

const EventLogPlay = (props) => {
  const { events, play, intl, updateState, currentState, playStore } = props;
  const { eventToReview } = currentState;
  const { formatMessage } = intl;
  const [inOT, setInOT] = useState(false);
  const [flagged, setFlagged] = useState(false);
  const [canEdit] = useCanEdit(props, events);
  const [hasEvents, setHasEvents] = useState(false);
  const [scores, setScores] = useState();
  const [showClockEdit, setShowClockEdit] = useState(false);
  const [clockEditTime, setClockEditTime] = useState(null);
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  const getIsHighlighted = () => {
    if (!eventToReview) {
      return false;
    }

    const { metadata } = eventToReview;

    return events.some((event) => {
      if (event.periodId !== parseInt(metadata.periodId, 10)) {
        return false;
      }

      if (event.clock !== metadata.clock) {
        return false;
      }

      if (event.entityId !== metadata.entityId) {
        return false;
      }

      const [eventType, outcome] = metadata.eventType.split("::");

      if (event.eventType !== eventType) {
        return false;
      }

      if (outcome === "made" && !event.success) {
        return false;
      }

      if (outcome === "missed" && event.success) {
        return false;
      }

      return true;
    });
  };

  let currentPlays = props.playStore
    .filter((onePlay) => onePlay.periodId === play.periodId)
    .sort((a, b) => (parseInt(a.eventTime) < parseInt(b.eventTime) ? -1 : 1));

  const lastValidPlay = getLastValidPlayFromPeriod(currentPlays, props.eventStore);

  const isInsertAfterEnabled = () => {
    if (play.playId !== lastValidPlay.playId) {
      return false;
    }

    const periodEndEvent = getPeriodEndEvent(currentState.mqtt?.plays ?? [], play.periodId);

    if (!periodEndEvent) {
      return false;
    }

    return true;
  };

  const calculateEventTimeMidPoint = () => {
    const compareClockWithEditTime = (playClock) => {
      const [aMin, aSec] = convertClock(playClock)
        .split(":")
        .map((t) => parseInt(t, 10));
      let [bMin, bSec] = clockEditTime.split(":").map((t) => parseInt(t, 10));
      if (sport === "handball" && play.periodId > 1 && play.periodId < fixtureProfile.initialExtraPeriodId) {
        bMin -= fixtureProfile.periodLength;
      }
      return aMin * 60 + aSec - (bMin * 60 + bSec);
    };

    const plays = [...playStore];
    const playsWithClock = plays
      .filter((onePlay) => onePlay.periodId === play.periodId)
      .map((onePlay) => {
        return {
          ...onePlay,
          clock: onePlay.clock ?? "PT0M0S",
        };
      });

    if (playsWithClock.length < 3) {
      return play.eventTime;
    }

    let playA = null;
    let playB = null;
    if (sport === "handball") {
      playA = playsWithClock.findLast(
        (onePlay) => compareClockWithEditTime(onePlay.clock) >= 0 && onePlay.playId !== play.playId,
      );
      playB = playsWithClock.find(
        (onePlay) => compareClockWithEditTime(onePlay.clock) <= 0 && onePlay.playId !== play.playId,
      );
    } else {
      playA = playsWithClock.findLast(
        (onePlay) => compareClockWithEditTime(onePlay.clock) <= 0 && onePlay.playId !== play.playId,
      );
      playB = playsWithClock.find(
        (onePlay) => compareClockWithEditTime(onePlay.clock) >= 0 && onePlay.playId !== play.playId,
      );
    }

    if (!playA || !playB) {
      return play.eventTime;
    }

    const eventTimeMidPoint = new Date(
      Math.floor(
        (new Date(`${playA.eventTime}${playA.eventTime.includes("Z") ? "" : "Z"}`).getTime() +
          new Date(`${playB.eventTime}${playB.eventTime.includes("Z") ? "" : "Z"}`).getTime()) /
          2,
      ),
    ).toISOString();
    return eventTimeMidPoint;
  };

  const handleClockClick = () => {
    if (!showClockEdit) {
      setClockEditTime(
        convertEventTimeClock({
          clock: play.clock,
          periodId: play.periodId,
          sport,
          fixtureProfile,
        }),
      );
    } else {
      let [clockEditMin, clockEditSec] = clockEditTime.split(":").map((t) => parseInt(t, 10));
      if (sport === "handball" && play.periodId > 1) {
        clockEditMin -= fixtureProfile.periodLength;
      }
      const [playMin, playSec] = convertClock(play.clock ?? "PT0M0S")
        .split(":")
        .map((t) => parseInt(t, 10));
      if (clockEditMin !== playMin || clockEditSec !== playSec) {
        events.forEach((event) => {
          const sendAction = {
            action: "sendEvent",
            event: {
              ...event,
              status: "updated",
              clock: convertDisplayTimeToDataTime(
                convertToFullDisplayTime({
                  clock: clockEditTime,
                  periodId: play.periodId,
                  sport,
                  fixtureProfile,
                }),
              ),
              eventTime: calculateEventTimeMidPoint(),
            },
            type: "custom",
          };
          sendEvent(sendAction, props);
        });
      }
    }
    setShowClockEdit(!showClockEdit);
  };

  const handleClockChange = (newTime) => {
    setClockEditTime(newTime);
  };

  useEffect(() => {
    let flagged = events.find((el) => el.flagged === true);
    if (typeof flagged !== "undefined") {
      setFlagged(true);
    } else {
      setFlagged(false);
    }
    let visibleEvents = events.filter((el) => el.status !== "deleted");
    if (visibleEvents.length > 0) {
      setHasEvents(true);
    }
    // eslint-disable-next-line
  }, [events]);

  useEffect(() => {
    let scoreTypes = defaultSetup.scoreTypes;
    let hasScoringEvents = events.filter((ev) => Object.keys(scoreTypes).includes(ev.eventType) && ev.success === true);

    if (
      hasScoringEvents.length > 0 &&
      events &&
      currentState.mqtt &&
      currentState.mqtt.plays &&
      currentState.mqttConnected &&
      currentState.connected
    ) {
      let actualEvent = currentState.mqtt.plays.find((pbpEv) => pbpEv.eventId === events[events.length - 1].eventId);

      if (actualEvent) {
        setScores(
          <span className="event-log-scores">
            {currentState.entities.map((team) => {
              return (
                <span
                  key={team.entityId}
                  className={"event-log-score " + (team ? lightOrDark(team.primaryColor) : "dark")}
                  style={{ backgroundColor: team.primaryColor }}
                >
                  {actualEvent.scores[team.entityId]}
                </span>
              );
            })}
          </span>,
        );
      }
    } else {
      // clear previous scores template when no scores event found
      setScores(null);
    }
    // eslint-disable-next-line
  }, [currentState.updateLog, events, currentState.mqtt, currentState.mqttConnected, currentState.connected]);

  useEffect(() => {
    if (play) {
      if (play.periodId >= fixtureProfile.initialExtraPeriodId) {
        setInOT(true);
      }
    }
    // eslint-disable-next-line
  }, [play]);

  const extraPlayInfo = useMemo(() => getExtraPlayInfo(events), [events]);

  function _editPlay(play) {
    updateState("eventToReview", null);
    if (!currentState.primary && !currentState.secondary) {
      updateState("modify", { playId: play.playId, state: null });
    }
  }

  function _setInsertMode(play) {
    if (!currentState.primary && !currentState.secondary) {
      updateState("insertMode", { enabled: true, play: play });
    }
  }

  function _setInsertAfterMode(play) {
    if (!currentState.primary && !currentState.secondary) {
      updateState("insertMode", { enabled: true, play: play, isAfter: true });
    }
  }

  const handleFlagClicked = () => {
    const newIsFlagged = !flagged;
    const playEvents = props.eventStore.filter((ev) => ev.playId === play.playId);
    playEvents.forEach((event) => {
      event.eventTime = getEventTimeForUpdatedDeletedEvents(event);
      event.status = "updated";
      event.flagged = newIsFlagged;
      let action = {
        action: "sendEvent",
        event: event,
        type: "custom",
      };
      sendEvent(action, props);
    });
    const tempEvents = currentState.events;
    tempEvents.main.flagged = newIsFlagged;
    updateState("events", tempEvents);
    setFlagged(newIsFlagged);
  };

  return (
    hasEvents &&
    play && (
      <div className={`event-log-play flagged-${flagged} highlighted-${getIsHighlighted()}`}>
        <div className="play-header">
          <span className="play-period">
            {!inOT && (
              <span>
                {formatMessage({
                  id: "period.abbreviation",
                  defaultMessage: "P",
                })}
              </span>
            )}
            {inOT && (
              <span>
                {formatMessage({
                  id: ["hockey", "handball"].includes(sport)
                    ? "period.overtime.shootout.abbreviation"
                    : "period.overtime.abbreviation",
                  defaultMessage: ["hockey", "handball"].includes(sport) ? "SO" : "OT",
                })}
              </span>
            )}
            {(!inOT || (inOT && !["hockey", "handball"].includes(sport))) && (
              <>
                {parseInt(play.periodId) >= fixtureProfile.initialExtraPeriodId
                  ? parseInt(play.periodId) - (fixtureProfile.initialExtraPeriodId - 1)
                  : play.periodId}
              </>
            )}
          </span>
          <div>
            <span className="play-clock" onClick={handleClockClick}>
              {play.clock &&
                convertEventTimeClock({
                  clock: play.clock,
                  periodId: play.periodId,
                  sport,
                  fixtureProfile,
                })}
            </span>
            {showClockEdit && (
              <EventLogDropdown onOutsideClick={() => handleClockClick()}>
                <ClockEditor time={clockEditTime} play={play} playStore={playStore} onChange={handleClockChange} />
              </EventLogDropdown>
            )}
          </div>
          {scores}
          <div className="event-log-play-controls">
            <div className="flag-play" onClick={handleFlagClicked}>
              <i className="bookmark fas fa-bookmark"></i>
              <i className="star fas fa-star"></i>
            </div>
            {isInsertAfterEnabled() && (
              <div
                className={
                  "insert-play insert-after enabled-" + (currentState.insertMode && !currentState.insertMode.enabled)
                }
                onClick={() => _setInsertAfterMode(play)}
              >
                <i className="fas fa-plus"></i>
                <i className="fas fa-level-down-alt"></i>
              </div>
            )}
            <div
              className={"insert-play enabled-" + (currentState.insertMode && !currentState.insertMode.enabled)}
              onClick={() => _setInsertMode(play)}
            >
              <i className="fas fa-plus"></i>
              <i className="fas fa-level-down-alt"></i>
            </div>
            {canEdit && (
              <div
                className={"edit-play enabled-" + (currentState.insertMode && !currentState.insertMode.enabled)}
                onClick={() => _editPlay(play)}
              >
                <i className="fas fa-pencil-alt"></i>
              </div>
            )}
          </div>
        </div>
        <EventLogItems {...props} events={events} extraPlayInfo={extraPlayInfo} />
      </div>
    )
  );
};

export default React.memo(injectIntl(EventLogPlay));
