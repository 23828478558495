import "./ClockEditorSegment.scss";

const ClockEditorSegment = (props) => {
  const { value, rollover, onChange } = props;

  const handleValueChange = (delta) => {
    const newValue = parseInt(value) + delta;
    if (rollover) {
      if (newValue < rollover[0]) {
        onChange(rollover[1] - 1, -1);
      } else if (newValue > rollover[1]) {
        onChange(rollover[0] + 1, 1);
      } else {
        onChange(newValue, 0);
      }
    } else {
      onChange(newValue);
    }
  };

  return (
    <div className="clock-editor-segment-root">
      <div className="clock-editor-segment-time">{value}</div>
      <div className="clock-editor-segment-buttons">
        <div
          className="clock-editor-segment-button"
          onClick={() => {
            handleValueChange(1);
          }}
        >
          <i className="fas fa-caret-up" />
        </div>
        <div
          className="clock-editor-segment-button"
          onClick={() => {
            handleValueChange(-1);
          }}
        >
          <i className="fas fa-caret-down" />
        </div>
      </div>
    </div>
  );
};

export default ClockEditorSegment;
