import ClockEditorSegment from "./ClockEditorSegment";

import "./ClockEditor.scss";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { useContext, useMemo } from "react";
import { convertClock } from "../../../Base/Utilities/ConvertClock";

const ClockEditor = (props) => {
  const { time, play, onChange, playStore } = props;
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  const [minutes, seconds] = time.split(":");

  const playPeriodsClocks = useMemo(() => {
    const plays = [...playStore];
    let playPeriods = {};
    plays.forEach((play) => {
      if (!playPeriods[play.periodId]) {
        playPeriods[play.periodId] = [];
      }
      playPeriods[play.periodId].push(play);
    });
    Object.keys(playPeriods).forEach((periodId) => {
      playPeriods[periodId] = playPeriods[periodId].map((play) => play.clock ?? "PT0M0S");
    });
    return playPeriods;
  }, playStore);

  const isNotHandballOrFirstPeriod = () => {
    return sport !== "handball" || play.periodId === 1 || play.periodId >= fixtureProfile.initialExtraPeriodId;
  };

  const convertAndSplitClock = (clock) => {
    return convertClock(clock)
      .split(":")
      .map((t) => parseInt(t, 10));
  };

  const getMaxSeconds = () => {
    const playClocks = playPeriodsClocks[play.periodId] ?? [];
    let firstPlayClock = playClocks?.[0] ?? "PT0M0S";
    if (sport !== "handball") {
      firstPlayClock = playClocks?.[playClocks.length - 1] ?? "PT0M0S";
    }
    let [mins, secs] = convertAndSplitClock(firstPlayClock);
    if (isNotHandballOrFirstPeriod()) {
      return mins * 60 + secs;
    }
    mins = Math.min(mins + fixtureProfile.periodLength, fixtureProfile.periodLength * 2);
    return mins * 60 + secs;
  };

  const getMinSeconds = () => {
    const playClocks = playPeriodsClocks[play.periodId] ?? [];
    let lastPlayClock = playClocks?.[playClocks.length - 1] ?? "PT0M0S";
    if (sport !== "handball") {
      lastPlayClock = playClocks?.[0] ?? "PT0M0S";
    }
    let [mins, secs] = convertAndSplitClock(lastPlayClock);
    if (isNotHandballOrFirstPeriod()) {
      return mins * 60 + secs;
    }
    mins = Math.min(mins + fixtureProfile.periodLength, fixtureProfile.periodLength * 2);
    return mins * 60 + secs;
  };

  const calculateNewTime = (mins, secs) => {
    const allSeconds = Math.min(Math.max(mins * 60 + secs, getMinSeconds()), getMaxSeconds());
    const newMinutes = Math.floor(allSeconds / 60);
    const newSeconds = allSeconds - newMinutes * 60;
    return [newMinutes, newSeconds];
  };

  const formatTime = (mins, secs) => {
    return mins.toString().padStart(2, 0) + ":" + secs.toString().padStart(2, 0);
  };

  const handleMinutesChange = (mins) => {
    const [newMinutes, newSeconds] = calculateNewTime(parseInt(mins, 10), parseInt(seconds, 10));
    onChange(formatTime(newMinutes, newSeconds));
  };

  const handleSecondsChange = (secs, carry) => {
    const [newMinutes, newSeconds] = calculateNewTime(parseInt(minutes, 10) + carry, parseInt(secs, 10));
    onChange(formatTime(newMinutes, newSeconds));
  };

  return (
    <div className="clock-editor-root">
      <ClockEditorSegment value={minutes} onChange={handleMinutesChange} />
      <ClockEditorSegment value={seconds} rollover={[0, 60]} onChange={handleSecondsChange} />
    </div>
  );
};

export default ClockEditor;
