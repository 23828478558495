export function checkGameStarted(props) {
  let status = false;
  if (props.currentState && props.currentState.period) {
    switch (props.currentState.period.periodId) {
      case 1:
        status = !["pending", "initial", "warm_up"].includes(props.currentState.period.periodStatus);
        break;
      default:
        status = true;
        break;
    }
  }
  return status;
}
